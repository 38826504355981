<template>

  <y-card-subtitle>
    Tous les joueurs
  </y-card-subtitle>

  <ul class="divide-y">
    <li
      class="px-4 py-3 flex items-center"
      v-for="player in getEventPlayers"
      :key="player.id">
      <y-player-summary :player="player" />
    </li>
  </ul>

</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { sortFirebaseObjectBy } from '@/classes/Tools';

import YCardSubtitle from '@/UI/YCardSubtitle.vue';
import YPlayerSummary from '@/UI/YPlayerSummary.vue';

export default {

  components: {
    YCardSubtitle,
    YPlayerSummary,
  },

  setup() {
    const store = useStore();
    const getEventPlayers = computed(() => sortFirebaseObjectBy(store.getters.getEventPlayers, 'name'));

    return {
      getEventPlayers,
    };
  },

};
</script>
